import { useContext } from 'react'
import { FormContext } from '../context/FormContext'
import { UserContext } from '../context/UserContext'

const Header = () => {

    const {showFormControls, formNameControls, formPriceControls, existingImageControls,formIdControls } = useContext(FormContext)
    const [showNewProductFormState, setShowNewProductFormState] = showFormControls
    /* eslint-disable no-unused-vars */
    const [nameFormState, setNameFormState] = formNameControls
    const [formPriceState, setPriceState] = formPriceControls
    const [existingImageState, existingImageSetstate] = existingImageControls
    const [formIdState, formIdSetstate] = formIdControls
    /* eslint-enable no-unused-vars */
    const userControls = useContext(UserContext)
    const [user,setUser] = userControls
    

    const logout = ()=>{
        localStorage.clear();
        setUser(false);
    }
    

    return (
        <div className="header">
            <h1>Products Database</h1>
            <nav>
                <ul>
                    {!user ? <li>Login</li> : <li onClick={logout}>Logout</li> }
                    <li onClick={ ()=>{setShowNewProductFormState(!showNewProductFormState); setNameFormState(''); setPriceState('');existingImageSetstate(''); formIdSetstate('') }} >Add New Product</li>
                    <li>Something Else</li>
                </ul>
            </nav>
            
        </div>
    )
}

export default Header


