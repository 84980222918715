import { useContext, useEffect} from 'react'
import Product from './Product'
import { ProductContext } from '../context/ProductContext'
import { FormContext } from '../context/FormContext'
import { UserContext } from '../context/UserContext'
import { Redirect } from 'react-router-dom'

const Products = () => {

    const [allProducts, setAllProducts ] = useContext(ProductContext)
    const {showFormControls, formNameControls, formPriceControls, existingImageControls,formIdControls } = useContext(FormContext)
    const [showNewProductFormState, setShowNewProductFormState] = showFormControls
    const [nameFormState, setNameFormState] = formNameControls
    const [formPriceState, setPriceState] = formPriceControls
    const [existingImageState, existingImageSetstate] = existingImageControls
    const [formIdState, formIdSetstate] = formIdControls

    const [user,setUser] = useContext(UserContext)
    // const [user,setUser] = userControls
    

    useEffect( ()=> {

        if ( user ) {
            let token 
            localStorage.getItem("user") ?  token = JSON.parse(localStorage.getItem("user")).token : token = ""
            fetch('https://api.webstudiox.net/app/products', { 
                method: 'GET',
                headers:{Authorization: "Bearer " + token}
            })
            .then( response => {
                if (response.status === 201) {
                    return response.json()
                    
                }else{
                    setAllProducts('')
                    setUser(false)
                    return 
                } 
            })
            .then( data =>{
                setAllProducts(data)
            })  
            .catch( err => {
                console.log('ERROR LOADING NEW LIST') 
                console.log(err) 
            })      
        }else{
            setAllProducts('')
        }

        return () => {
            setAllProducts('')
        };
    },[ setAllProducts, setUser, user] )
    

 
    const deleteProduct = async (id) =>{
        let token 
        localStorage.getItem("user") ?  token = JSON.parse(localStorage.getItem("user")).token : token = ""

        if (window.confirm("permanetly delete this product?") ) {
             
            const deleteIt = fetch(`https://api.webstudiox.net/app/products/${id}`, { method: 'DELETE', headers:{Authorization: "Bearer " + token}  })
            .catch( err => {
                console.log('ERROR DELETING') 
                console.log(err)  
            })

            deleteIt.then( ()=>
                fetch('https://api.webstudiox.net/app/products',{ 
                    method: 'GET',
                    headers:{Authorization: "Bearer " + token}
                })
            )
            .then( (response)=>{
                if (!response.ok) {
                    throw new Error(response.status)
                }else{
                    return response.json()
                }
            })
            .then( 
                updatedList =>{
                    // update state here
                    setAllProducts(updatedList)
                } 
            )
            .catch( err => {
                console.log('ERROR LOADING NEW LIST') 
                console.log(err)  
                setUser(false)
            })
 
        }
    }



    const editProduct = async (id, name, price, file) =>{   
        formIdSetstate(id) 
        setShowNewProductFormState(!showNewProductFormState)
        setNameFormState(name)
        setPriceState(price)
        existingImageSetstate('https://api.webstudiox.net/app/'+file)
    }
    

    if (allProducts) {
        return (
        
            <div className="product-container">
                <table>
                    <tbody>
                        <tr><th className="th-product">PRODUCT</th>
                            <th className="th-price table-ctr">Price</th>
                            <th className="th-image table-ctr">Image</th>
                            <th className="th-edit table-ctr">Edit</th>
                            <th className="th-delete table-ctr">Delete</th>
                        </tr>
    
                        {allProducts.result && allProducts.result.map((eachProduct) => (
                            <Product 
                                key={eachProduct._id} 
                                productId={eachProduct._id} 
                                productName={eachProduct.name} 
                                productPrice={eachProduct.price} 
                                imgSrc={eachProduct.product_image}
                                delete={deleteProduct}  
                                edit={editProduct}  
                            />
                        ))}
                    </tbody>
                </table>
    
            </div>
        )
        
    }else{

        return <Redirect to="/login" />;
    }

}

export default Products