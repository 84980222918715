import {  useState, useContext } from "react"
import { Redirect } from 'react-router-dom'
import { UserContext } from '../context/UserContext'

const Login = () => {
    
    // const [loggedin, setLoggedin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailValidationText, setEmailValidationText] = useState('');
    const [passwordValidationText, setPasswordValidationText] = useState('');
    const [loginErrorTxt, setLoginErrorTxt] = useState('')

    const userControls = useContext(UserContext)
    const [user,setUser] = userControls

    const validateAndSubmit = async (e)=>{
        e.preventDefault();
        setEmailValidationText('');
        setPasswordValidationText(''); 
        let goodToGo = true;

        const emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
             
        if (email.length<6 || !emailregex.test(email) ) {
            setEmailValidationText("Please enter a valid email address") 
            goodToGo = false
        }
        if (password.length<7) {
            setPasswordValidationText('Please enter a valid password') 
            goodToGo = false
        }
        

        if (goodToGo) {
            setLoginErrorTxt("")
            fetch('https://api.webstudiox.net/app/users/login',{
                method:'POST',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json'},
                body:JSON.stringify({email:email, password:password})
            })
            .then( (response)=>{

                if (!response.ok) {
                    throw new Error(response.status)
                }else{
                    return response.json()
                }
            } )
            // .then(response => response.json())
            .then(response => {
                setUser(JSON.stringify(response))
                localStorage.setItem('user', JSON.stringify(response))           
            })
            .catch(err =>{
                setLoginErrorTxt("Please try again..")
                setPassword('')
                console.log('ERROR') 
                console.log(err)  
            })

        }else{
            return false
        }
    }


    if (user) {
        
        return <Redirect to="/" />;

    }else{
        return (
            <div className="login-container">

                <h1>LOGIN</h1> 
                <form className="registration-form" onSubmit={validateAndSubmit}>
                    <p>{loginErrorTxt}</p>
                    <label>Email:</label><input type="email" value={email} onChange={ (e)=> setEmail(e.target.value) } /> <br />
                    <label>Password:</label><input type="password" value={password} onChange={ (e)=> setPassword(e.target.value) }/> <br />
                    <p className="form-validation">{emailValidationText}</p>
                    <p className="form-validation">{passwordValidationText}</p>
                    <input type="submit" value="LOGIN"/>

                </form>

            </div>
        )

    }
}

export default Login
