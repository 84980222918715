
const Product = (props) => {
    return (
        props.productName &&
        <tr>
            <td>{props.productName}</td>
            <td className="table-ctr">{props.productPrice}</td> 
            <td className="table-ctr">{props.imgSrc? <img style={{width:'100px'}} src={`https://api.webstudiox.net/app/${props.imgSrc}`} alt="" /> : ""}</td>
            <td className="table-ctr"><button onClick={ ()=> props.edit(props.productId, props.productName, props.productPrice, props.imgSrc)}>Edit Product</button></td>
            <td className="table-ctr"><button onClick={ ()=> props.delete(props.productId)}>Delete Product</button></td>
        </tr>           
        
    )
}

export default Product
