
import { useState , useContext} from "react"
import { ProductContext } from '../context/ProductContext'
import { FormContext } from '../context/FormContext'
import { UserContext } from '../context/UserContext'

const ProductNewForm = () => {

  //if there's a formIdState then it's an update, otherwise new product to upload.

  const [allProducts, setAllProducts ] = useContext(ProductContext)

  const {showFormControls, formNameControls, formPriceControls, existingImageControls,formIdControls } = useContext(FormContext)
  const [showNewProductFormState, setShowNewProductFormState] = showFormControls
  const [nameFormState, setNameFormState] = formNameControls
  const [formPriceState, setPriceState] = formPriceControls
  const [existingImageState, existingImageSetstate] = existingImageControls
  const [formIdState, formIdSetstate] = formIdControls


  const [selectedFile, setSelectedFile] = useState(null);
  const [hasImageChanged, setHasImageChanged] = useState(false);

  const [user,setUser] = useContext(UserContext)


  const handleSubmit = async(e)=>{
    e.preventDefault()  

    let token 
    localStorage.getItem("user") ?  token = JSON.parse(localStorage.getItem("user")).token : token = ""


    let headers,method,body,endpoint
    const authorization = "Bearer " + token

    if (!selectedFile) {
      //no image to upload
      body = JSON.stringify({name:nameFormState,price:formPriceState})
      headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization':authorization}
      formIdState ? 
        endpoint = `https://api.webstudiox.net/app/products/updatewithoutimage/${formIdState}` : 
        endpoint = 'https://api.webstudiox.net/app/products/newwithoutimage' ;
      formIdState ? method = 'PATCH' : method = 'POST'
    
    }else{
      //image to upload
      body = new FormData();   
      body.append('productImage',selectedFile);
      body.append('name',nameFormState);
      body.append('price',formPriceState);
      headers = {'Authorization':authorization}
      formIdState ? 
        endpoint = `https://api.webstudiox.net/app/products/updatewithimage/${formIdState}` : 
        endpoint = 'https://api.webstudiox.net/app/products/newwithimage' ;
      formIdState ? method = 'PATCH' : method = 'POST'

    }
 
    const saveIt =  fetch( endpoint, { method: method, headers: headers,  body: body })

    saveIt.then( ()=>
      fetch('https://api.webstudiox.net/app/products',{ 
        method: 'GET',
        headers:{Authorization: "Bearer " + token,}
      })

    )
    .then( (response)=>{
      if (response.status === 401) {

        setUser(false)
        throw new Error(response.status)

      }
      else if (!response.ok) {
        throw new Error(response.status)

      }else{
        return response.json()
      }
    })
    .then( 
      updatedList =>{
          // update state here
          setAllProducts(updatedList)
      } 
    )
    .catch( err => {
      window.alert("Sorry, something went wrong")
      console.log('ERROR LOADING NEW LIST') 
      console.log(err)  
    })
    .finally(
      ()=>{
        setHasImageChanged(false)
        existingImageSetstate(null) 
        setSelectedFile(null)
        setShowNewProductFormState(false)
      }
    )
  }


  const imageChange = (e) => {
    //this will be uploaded
    setSelectedFile(e.target.files[0])
    //update the preview image in the form
    existingImageSetstate(URL.createObjectURL(e.target.files[0]))
    //tells upload fetch that ther''s a new image to patch/post
    setHasImageChanged(true)
  }
    
  return (
    showNewProductFormState &&
    <div className="product-form-container">

      {formIdState ? <h2>Edit Product</h2> : <h2>Add New Product</h2>}

      <button className="close-btn" onClick={()=>{ setShowNewProductFormState(!showNewProductFormState) }}>CLOSE</button>
      
      <form onSubmit={handleSubmit}>

        {formIdState && <><label htmlFor="idInput">ID:</label> <input id="idInput" type="text" value={formIdState} disabled/><br /></>}
        <label htmlFor="nameInput">Product Name:</label> <input type="text" value={nameFormState} onChange={ (e)=> setNameFormState(e.target.value) } id="nameInput"  /><br />
        <label htmlFor="priceInput">Product Price:</label> <input type="text" value={formPriceState} onChange={ (e)=> setPriceState(e.target.value) }  id="priceInput" /><br />
        
        <div>          
          {existingImageState 
            ? <><img style={{width:'100px'}} src={existingImageState} alt=""/> <span onClick={()=>existingImageSetstate(null)}> Remove</span></>
            : <><label htmlFor="">Product Image:</label> <input type="file" id="fileInput" onChange={ imageChange }  /> <br /></>
          }
          {selectedFile === existingImageState ? <p>Same Files</p> : <p>Differnet Files</p>}
        </div>
        <input type="submit" value={formIdState ? "Save Changes" : "Save New Product"}/>
        
      </form>

    </div>
  )
}

export default ProductNewForm
