import { createContext, useState } from "react"


export const ProductContext = createContext()

export const ProductProvider = props => {

    const [productsState, productsSetstate] = useState( [] )

    return(
        <ProductContext.Provider value={[productsState,productsSetstate]}>
            {props.children}
        </ProductContext.Provider>
    )


}