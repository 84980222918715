import { createContext, useState} from "react"

export const FormContext = createContext()

export const FormProvider = props => {

    const [showFormState, showFormSetstate] = useState( false )
    const [formNameState, formNameSetstate] = useState( '' )
    const [formPriceState, formPriceSetstate] = useState( '' )
    const [formFileState, formFileSetstate] = useState( '' )
    const [formIdState, formIdSetstate] = useState( '' )

    
    return(
        <FormContext.Provider 
            value={{
                showFormControls: [showFormState,showFormSetstate], 
                formNameControls: [formNameState, formNameSetstate],
                formPriceControls: [formPriceState, formPriceSetstate],
                existingImageControls: [formFileState, formFileSetstate],
                formIdControls: [formIdState, formIdSetstate]
            } }
         >
            {props.children}
        </FormContext.Provider>
    )


}