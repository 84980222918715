import { useState } from "react"

const Register = () => {

    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRpt, setNewPasswordRpt] = useState('');
    const [emailValidationText, setEmailValidationText] = useState('');
    const [passwordValidationText1, setPasswordValidationText1] = useState('');
    const [passwordValidationText2, setPasswordValidationText2] = useState('');




    const validateAndSubmit= (e)=>{
        e.preventDefault()

        let goodToGo = true
        /* eslint-disable-next-line no-useless-escape*/
        const emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
             
        if (newEmail.length<6 || !emailregex.test(newEmail) ) {
            setEmailValidationText('Please enter a valid email address') 
            goodToGo = false
        }

        const passwordregex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if (!newPassword.match(passwordregex)) {
            setPasswordValidationText1('Password must contain between 7 to 15 characters including at least one number and a special character')
            goodToGo = false
        }else{
            setPasswordValidationText1('')
        }

        
        if (newPasswordRpt !== newPassword) {
            setPasswordValidationText2('Password do not match.')
            goodToGo = false
        }else{
            setPasswordValidationText2('')
        }

        if (goodToGo) {


            fetch('https://api.webstudiox.net/app/users/register',{
                method:'POST',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json'},
                body:JSON.stringify({email:newEmail, password:newPassword})
            })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(err =>{
                console.log('ERROR') 
                console.log(err)  
            })
        }
        

    }




    

    
    return (
        <div className="registration-container">

            <h1>REGISTRATION PAGE</h1> 
            <form className="registration-form">

                <label>Email:</label><input type="email" value={newEmail} onChange={ (e)=> setNewEmail(e.target.value) } /> <br />
                <p className="form-validation">{emailValidationText}</p>

                <label>Set Password:</label><input type="password" value={newPassword} onChange={ (e)=> setNewPassword(e.target.value) }/> <br />
                <label>Retype Password:</label><input type="password" value={newPasswordRpt} onChange={ (e)=> setNewPasswordRpt(e.target.value) } /> <br />
                <p className="form-validation">{passwordValidationText1}</p>
                <p className="form-validation">{passwordValidationText2}</p>
                <input type="submit" value="REGISTER" onClick={validateAndSubmit}/>

            </form>

        </div>
    )
}

export default Register
