import './App.css';
// import  {useState} from 'react'
// import { useEffect } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import { ProductProvider } from './context//ProductContext'
import { FormProvider } from './context/FormContext'
import { UserProvider } from './context/UserContext';

import Header from './components/Header';
import Products from './components/Products'
import ProductNewForm from './components/ProductNewForm';
import Register from './components/Register';
import Login from './components/Login';



function App() {

  return (
    <Router>
      <div className="App">
        <UserProvider>
        <FormProvider>
          <Header />
      
          <Route path='/' exact render={ ()=>(
            <>
              <ProductProvider>         
                <Products />
                <ProductNewForm />  
              </ProductProvider>         
            </>
            ) } 
          />


          <Route path='/register' render={()=>(
              <>
                <Register />
              </>
            )} 
          />


          <Route path='/login' render={()=>(
              <>
                <Login />
              </>
            )} 
          />



        </FormProvider> 
        </UserProvider>
        
      </div>
    </Router>
  );
}

export default App;
